<template>
  <v-container>
    <!-- ============================================================= -->
    <!-- Select log folder button -->
    <!-- ============================================================= -->
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <v-btn @click="onChooseFolderBtn" depressed color="primary">
          Select Frames Folder
        </v-btn>
      </v-col>
    </v-row>

    <!-- ============================================================= -->
    <!-- Frame screenshot -->
    <!-- ============================================================= -->
    <v-row>
      <v-col>
        <img class="frameImg" src="" alt="" ref="frameImg" />
      </v-col>
    </v-row>

    <!-- ============================================================= -->
    <!-- Frame lists -->
    <!-- ============================================================= -->
    <div class="flex-containter">
      <div class="frame-list">
        Frame list
        <li
          v-for="(folder, index) in framesFolders"
          :key="index"
          @click="onFrameFolderClick(folder)"
        >
          <!-- {{ index }} - Name = {{ folder.name }} -->
          {{ folder.name }}
        </li>
      </div>

      <!-- ============================================================= -->
      <!-- Function call lists -->
      <!-- ============================================================= -->
      <div class="log-list">
        Function call list
        <li v-for="(functionCall, index) in functionCallList" :key="index">
          {{ index }} - {{ functionCall }}
        </li>
      </div>
      <div class="stackTrace">Stack trace</div>
    </div>
  </v-container>
</template>

<script>
// import Consts from '@/js/constants';
// import FileList from '@/components/FileList';

export default {
  name: 'ExtremeLog',
  //   components: {
  //     FileList
  //   },

  data: () => ({
    // fileHandle: null,
    // fileData: null,
    // fileText: '',
    // assetFolderHandle: null,
    // csFiles: null,
    // selectedFiles: [],
    // devoolioLogFiles: null,
    // devoolioLogFileText: null,
    // functionID: 0,
    framesFolders: [],
    frameImgSource: '',
    functionCallList: []
  }),

  //    mounted() {
  //     console.log(`the component is now mounted.`);
  //     // this.$refs["frameImg"].src="@/assets/framePlaceholder.jpg";
  //     console.log(this.$refs);
  //     console.log(this.$refs.frameImg.src);
  //     this.$refs.frameImg.src = "/assets/framePlaceholder.jpg";
  //     console.log(this.$refs.frameImg.src);

  //     // this.$refs.input.focus()
  //   },

  methods: {
    //from this tutorial: https://www.youtube.com/watch?v=8EcBJV0sOSU
    // async getSingleFile() {
    //   // open file picker
    //   [this.fileHandle] = await window.showOpenFilePicker();
    //   console.log(this.fileHandle);
    //   this.fileData = await this.fileHandle.getFile();
    //   console.log(this.fileData);
    //   this.fileText = await this.fileData.text();

    //   //   if (this.fileHandle.kind === 'file') {
    //   //     console.log(this.fileHandle);
    //   //   } else if (this.fileHandle.kind === 'directory') {
    //   //     // run directory code
    //   //   }
    // },

    async onChooseFolderBtn() {
      await this.getAllFoldersInFramesFolder();

      //   await this.getAllCsAndLogFiles();
      //   await this.readTextFromLogFile();
    },

    async getAllFoldersInFramesFolder() {
      const dirHandle = await window.showDirectoryPicker();
      for await (const entry of dirHandle.values()) {
        console.log(entry.kind, entry.name);
        if (entry.kind === 'directory') {
          this.framesFolders.push(entry);
        }
      }
      console.log('calling sort');
      this.framesFolders.sort((a, b) =>
        parseInt(a.name) > parseInt(b.name) ? 1 : -1
      );
      console.log('this.framesFolders', this.framesFolders);
    },

    onFrameFolderClick(folderHandle) {
      console.log('onFrameFolderClick(). folderHandle = ', folderHandle);
      this.loadImgAndFuncCallsFromSingleFrameFolder(folderHandle);
    },

    async loadImgAndFuncCallsFromSingleFrameFolder(folderHandle) {
      console.log('folderHandle.values()', folderHandle.values());

      //loop over each file / folder in the frame folder
      for await (const entry of folderHandle.values()) {
        // console.log(entry);
        if (entry.name === 'screnshot.png') {
          this.updateImgFromFrameFolder(entry);
        } else if (entry.name === 'DevoolioLog.txt') {
          let logFile = await entry.getFile();
          let logFileText = await logFile.text();
          // console.log(logFileText);
          this.functionCallList = logFileText.split(/\r?\n|\r|\n/g);

          //       file.fileData = await file.handle.getFile();
          //       //   console.log('file.fileData: ' + file.fileData);
          //       //   file.text = await file.fileData.text();
        }
      }
    },

    async updateImgFromFrameFolder(file) {
      console.log(file);
      file.fileData = await file.getFile();

      //https://web.dev/read-files/#read-content
      const reader = new FileReader();
      reader.addEventListener('load', event => {
        //https://masteringjs.io/tutorials/vue/img-tag
        this.$refs.frameImg.src = event.target.result;
      });
      reader.readAsDataURL(file.fileData);
    }

    // async getAllCsAndLogFiles() {
    //   //https://web.dev/file-system-access/#opening-a-directory-and-enumerating-its-contents
    //   //   const dirHandle = await window.showDirectoryPicker();
    //   //   for await (const entry of dirHandle.values()) {
    //   //     console.log(entry.kind, entry.name);
    //   //   }

    //   //https://stackoverflow.com/a/64310050/15546776
    //   try {
    //     this.assetFolderHandle = await window.showDirectoryPicker();
    //     var files = await this.listAllFilesAndDirs(this.assetFolderHandle);

    //     //only get the files in the list, not the directories
    //     files = files.filter(file => file.kind === 'file');

    //     //get the devoolop log file
    //     this.devoolioLogFiles = files.filter(
    //       file => file.name === Consts.DEVOOLIO_LOG_FILE_NAME
    //     );
    //     console.log('this.devoolioLogFiles', this.devoolioLogFiles);

    //     //now only get the .cs files
    //     files = files.filter(file => file.name.split('.').pop() === 'cs');

    //     //get the fileData and text for each file, and put them into the file object
    //     for (const file of files) {
    //       file.fileData = await file.handle.getFile();
    //       //   console.log('file.fileData: ' + file.fileData);
    //       //   file.text = await file.fileData.text();
    //     }

    //     this.csFiles = files;

    //     console.log('files', this.csFiles);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },

    // async readTextFromLogFile() {
    //   for (const logFile of this.devoolioLogFiles) {
    //     logFile.fileData = await logFile.handle.getFile();
    //     logFile.text = await logFile.fileData.text();
    //     this.devoolioLogFileText = logFile.text;
    //   }
    // },

    // async listAllFilesAndDirs(dirHandle) {
    //   const files = [];
    //   for await (let [name, handle] of dirHandle) {
    //     const { kind } = handle;
    //     if (handle.kind === 'directory') {
    //       //add the directory to the list too
    //       files.push({ name, handle, kind });

    //       //and then recursively get the files + folders in that directory
    //       files.push(...(await this.listAllFilesAndDirs(handle)));
    //     } else {
    //       files.push({ name, handle, kind });
    //     }
    //   }
    //   return files;
    // },

    // async onAddDebugLinesBtn() {
    //   //so we can count the number of functions
    //   this.functionID = 0;

    //   for (const file of this.csFiles) {
    //     let fileText = await file.fileData.text();
    //     fileText = await this.readLinesAndAddDebugToText(fileText);
    //     await this.saveFile(file.handle, fileText);
    //   }
    // },

    // readLinesAndAddDebugToText(fileText) {
    //   //   var lineArray = this.fileText.split('\n');
    //   var lineArray = fileText.split('\n');
    //   console.log('lineArray.length: ' + lineArray.length);

    //   for (var i = 0; i < lineArray.length; i++) {
    //     // console.log(i + ') ' + lineArray[i]);

    //     // //https://stackoverflow.com/questions/5873810/how-can-i-get-last-characters-of-a-string
    //     // var last3Chars = lineArray[i].substr(lineArray[i].length - 3);

    //     // if (last3Chars === '();') {
    //     //   console.log('last3Chars = ' + last3Chars);
    //     // }
    //     console.log('checking line: ' + lineArray[i]);

    //     // var functionName = null;
    //     var foundFunction = false;

    //     //get each word in the line. separated by a space, and white space trimmed
    //     var separateWordsInLine = lineArray[i].trim().split(' ');

    //     //check if this line is a function declaration. Eg, void MyFunc();
    //     for (var k = 0; k < separateWordsInLine.length; k++) {
    //       console.log('separateWordsInLine');
    //       console.log(separateWordsInLine[k]);

    //       if (separateWordsInLine[k] === 'void') {
    //         //get the function name which comes after the return value
    //         // functionName = separateWordsInLine[k + 1];
    //         foundFunction = true;
    //       }
    //     }

    //     // if (functionName != null) {
    //     if (foundFunction == true) {
    //       console.log('found function');

    //       var lineOffsetToAddNewLine = 0;

    //       //check where the start of the function brackets are. could be on the same line / or one after
    //       if (lineArray[i].trim().endsWith('{')) {
    //         lineOffsetToAddNewLine = 1;
    //       } else {
    //         lineOffsetToAddNewLine = 2;
    //       }

    //       //Add in a new line in the array
    //       //https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript
    //       //   lineArray.splice(
    //       //     i + lineOffsetToAddNewLine,
    //       //     0,
    //       //     'System.IO.File.AppendAllText(Application.dataPath + "/DevoolioLog.txt", "' +
    //       //       functionID +
    //       //       '|' +
    //       //       functionName +
    //       //       '|' +
    //       //       '" + this.GetType().Name + "' +
    //       //       '\\n");' +
    //       //       Consts.END_OF_LINE_COMMENT
    //       //   );

    //       lineArray.splice(
    //         i + lineOffsetToAddNewLine,
    //         0,
    //         'System.IO.File.AppendAllText(Application.dataPath + "/DevoolioLog.txt", "' +
    //           this.functionID +
    //           '|' +
    //           '"' +
    //           '+' +
    //           Consts.GET_CURRENT_METHOD_NAME +
    //           '+' +
    //           '"' +
    //           '|' +
    //           '" + this.GetType().Name + "' +
    //           '|' +
    //           '"' +
    //           '+' +
    //           Consts.GET_FRAME_COUNT_METHOD +
    //           '+' +
    //           '"' +
    //           '\\n");' +
    //           Consts.END_OF_LINE_COMMENT
    //       );

    //       //`System.IO.File.AppendAllText(Application.dataPath + "${Consts.NAME_OF_TEXT_FILE_TO_WRITE}", "${functionName}"`

    //       this.functionID += 1;

    //       //no need to check the line we just inserted
    //       // i++;
    //     }
    //   }

    //   //reconstruct the fileText from the separate lines array
    //   //   this.fileText = '';
    //   let newFileText = '';
    //   for (var j = 0; j < lineArray.length; j++) {
    //     newFileText += lineArray[j] + '\n';
    //   }

    //   return newFileText;
    // },

    // async onSaveBtnClick() {
    //   console.log('save btn');
    //   //   console.log('filetext = ');
    //   //   console.log(this.fileText);
    //   let stream = await this.fileHandle.createWritable();
    //   await stream.write(this.fileText);
    //   await stream.close();
    // },

    // async saveFile(fileHandle, text) {
    //   let stream = await fileHandle.createWritable();
    //   await stream.write(text);
    //   await stream.close();
    // },

    // async onRemoveBtnClick() {
    //   console.log('onRemoveBtnClick btn');

    //   for (const file of this.csFiles) {
    //     let fileText = await file.fileData.text();
    //     fileText = await this.removeLogLines(fileText);
    //     await this.saveFile(file.handle, fileText);
    //   }

    //   //   var lineArray = this.fileText.split('\n');
    //   //   console.log('lineArray.length: ' + lineArray.length);

    //   //   for (var i = 0; i < lineArray.length; i++) {
    //   //     if (lineArray[i].endsWith(Consts.END_OF_LINE_COMMENT)) {
    //   //       lineArray.splice(i, 1);
    //   //     }
    //   //   }

    //   //   this.fileText = '';

    //   //reconstruct the fileText from the separate lines array
    //   //   for (var j = 0; j < lineArray.length; j++) {
    //   //     this.fileText += lineArray[j] + '\n';
    //   //   }

    //   //   let stream = await this.fileHandle.createWritable();
    //   //   await stream.write(this.fileText);
    //   //   await stream.close();
    // },

    // removeLogLines(fileText) {
    //   var lineArray = fileText.split('\n');

    //   for (var i = 0; i < lineArray.length; i++) {
    //     if (lineArray[i].endsWith(Consts.END_OF_LINE_COMMENT)) {
    //       //remove one elements in the array at index i
    //       lineArray.splice(i, 1);

    //       //step back 1 so that we don't skip a line. this incase two
    //       //Devoolio log lines were added one after the other
    //       i -= 1;
    //     }
    //   }

    //   let newFileText = '';

    //   //reconstruct the fileText from the separate lines array
    //   for (var j = 0; j < lineArray.length; j++) {
    //     newFileText += lineArray[j] + '\n';
    //   }

    //   return newFileText;
    // },

    // async deleteLogFile() {
    //   console.log('deleteLogFile');

    //   //   for (const logFile of this.devoolioLogFiles) {
    //   //       console.log('logFile', logFile);
    //   //     // await logFile.handle.remove();
    //   //   }

    //   this.assetFolderHandle.removeEntry(Consts.DEVOOLIO_LOG_FILE_NAME);
    //   this.devoolioLogFileText = null;
    //   this.devoolioLogFiles = null;
    // }
  }
};
</script>

<style scoped>
.frameImg {
  width: 150px;
  height: 150px;
  border: black solid 2px;
}

.flex-containter {
  display: flex;
  min-height: 100px;
  max-height: 200px;
}

.frame-list {
  border: 3px solid #333;
  background-color: aqua;
  flex: 1;
  overflow: scroll;
}

.log-list {
  border: 3px solid #333;
  background-color: aqua;
  flex: 4;
  overflow: scroll;
}
</style>
