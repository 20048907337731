/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6674f7e1-a2b6-4cb9-a9af-bb3be7ab9327",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MzM8YtnkA",
    "aws_user_pools_web_client_id": "77qmdioga7jcdv2p4dvluqiv7g",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "19b364d6e5a54d54af647a39278cc39a",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
